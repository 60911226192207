<template>
  <div class="edit-grades">
    <content-not-view v-if="!subPermission.update" />
    <Loading v-else-if="is_loading" />
    <b-card v-else :title="$t('g.grades/edit_grade')">
      <!-- form -->
      <validation-observer ref="editGradeForm">
        <b-form>
          <b-row>
            <!-- Name -->
            <b-col md="12">
              <b-form-group :label="$t('g.grades/grade_name')" label-for="name">
                <validation-provider
                  #default="{ errors }"
                  :name="$t('g.grades/grade_name')"
                  rules="required"
                >
                  <b-form-input
                    v-model="form_data.name"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('g.placeholder.enterTheNameHere')"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Name -->

            <!-- Min Percentage -->
            <b-col md="12">
              <b-form-group
                :label="$t('g.grades/min_percentage')"
                label-for="name"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('g.grades/min_percentage')"
                  rules="required"
                >
                  <b-form-input
                    v-model="form_data.min_percentage"
                    type="number"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('g.placeholder/enterTheMinPercentageHere')"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Min Percentage -->

            <b-col cols="12">
              <b-button
                variant="primary"
                type="submit"
                @click.prevent="validationForm"
                :disabled="is_submitted"
              >
                <span
                  v-if="is_submitted"
                  class="loading_"
                  data-loading="light"
                />
                <span v-else>{{ $t("g.edit") }}</span>
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import {
  BButton,
  BCard,
  BCol,
  BForm,
  BFormCheckbox,
  BFormFile,
  BFormGroup,
  BFormInput,
  BRow,
} from "bootstrap-vue";
import { required } from "@core/utils/validations/validations";
import Loading from "@/components/loading/loading.vue";
import contentNotView from "@/components/contentNotView/contentNotView.vue";

export default {
  name: "EditGrades",
  components: {
    Loading,
    ValidationProvider,
    ValidationObserver,
    required,
    BFormCheckbox,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BCard,
    BFormFile,
    contentNotView,
  },
  data() {
    return {
      is_loading: true,
      is_submitted: false,
      form_data: {
        name: null,
        min_percentage: null,
      },
    };
  },
  computed: {
    subPermission() {
      this.$store.dispatch("GET_PERMISSION", "grades");
      return this.$store.state.userData.sub_Permission;
    },
  },
  beforeMount() {
    this.getGrade();
  },
  methods: {
    getGrade() {
      this.$http
        .get(`/admin/grades/${this.$route.params.id}`)
        .then((response) => {
          this.form_data.name = response.data.data.name;
          this.form_data.min_percentage = response.data.data.min_percentage;
          this.is_loading = false;
        })
        .catch((error) => {
          this.$helpers.handleError(error);
        });
    },
    validationForm() {
      this.$refs.editGradeForm.validate().then((success) => {
        if (success) {
          this.is_submitted = true;
          let formData = new FormData();
          formData.append("name", this.form_data.name);
          formData.append("min_percentage", this.form_data.min_percentage);

          this.$http
            .post(
              `/admin/grades/${this.$route.params.id}?_method=put`,
              formData
            )
            .then((response) => {
              if (response.status === 200 || response.status === 201) {
                this.$router.push({ name: "gradesList" });
                this.$helpers.makeToast(
                  "success",
                  response.data.message,
                  response.data.message
                );
              }
            })
            .catch((error) => {
              this.$helpers.handleError(error);
            });
        }
      });
    },
  },
};
</script>
